<template>
  <div class="section">
    <ModalEditPress
      v-if="modalEditPress"
      :close="activeModalEditPress"
      :dataPress="pressDataSelected"
      :action="updatePress"
      :loading="loading"
    />
    <ModalAddPress
      v-if="modalAddPress"
      :close="activeModalAddPress"
      :action="addPressAction"
      :loading="loading"
    />
    <nav class="level">
      <div class="level-left">
        <div class="level-item">
          <h1>Prensa</h1>
        </div>
      </div>
      <div class="level-right pr-5">
        <div class="level-item">
          <a
            @click="activeModalAddPress"
            class="button button-givu has-text-centered-mobile"
          >
            Agregar publicación
          </a>
        </div>
      </div>
    </nav>

    <div class="columns is-multiline">
      <press-list
        :pressDataList="pressDataList"
        :page="page"
        :pages="pages"
        :loading="loading"
        :paginator="paginator"
        :changeStatus="changeStatus"
        :editPress="editPress"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
const DEFAULT_DISPLAY_SIZE = 10

export default {
  name: 'Press',
  components: {
    PressList: () => import('@/components/Press/PressList'),
    ModalEditPress: () => import('@/components/Press/ModalEditPress'),
    ModalAddPress: () => import('@/components/Press/ModalAddPress')
  },
  data () {
    return {
      pressDataList: [],
      page: 1,
      pages: 1,
      loading: false,
      modalEditPress: false,
      pressDataSelected: {},
      modalAddPress: false
    }
  },
  methods: {
    ...mapActions(['getPressList', 'changePressStatus', 'addPress', 'editUpdatePress']),
    paginator (page) {
      this.page = page
      this.loading = true
      this.$router.push({ name: 'prensa', query: { page } })
      this.getPressList({ page, size: DEFAULT_DISPLAY_SIZE })
        .then(resp => {
          this.pressDataList = resp.payload
          this.pages = resp.totalPage
          this.loading = false
        })
    },
    async changeStatus (id) {
      let post = await this.changePressStatus(id)
      if (post) {
        let pressList = await this.getPressList({ page: this.page, size: DEFAULT_DISPLAY_SIZE })
        this.pressDataList = pressList.payload
        this.pages = pressList.totalPage
      }
      this.loading = false
    },
    editPress (data) {
      this.pressDataSelected = data
      this.activeModalEditPress()
    },
    async updatePress (data, pressId) {
      this.loading = true
      const resp = await this.editUpdatePress({ data, pressId })
      if (resp) {
        this.activeModalEditPress()
        this.page = 1
        this.paginator(this.page)
      }
      this.loading = false
    },
    activeModalEditPress () {
      this.modalEditPress = !this.modalEditPress
    },
    activeModalAddPress () {
      this.modalAddPress = !this.modalAddPress
    },
    async addPressAction (data) {
      this.loading = true
      const resp = await this.addPress(data)
      if (resp) {
        this.activeModalAddPress()
        this.page = 1
        this.paginator(this.page)
      }
      this.loading = false
    }
  },
  beforeMount () {
    this.loading = true
    const { page } = this.$router.history.current.query
    this.page = (page === '' || page === undefined) ? this.page : parseInt(page, 10)
    this.getPressList({ page: page || this.page, size: DEFAULT_DISPLAY_SIZE })
      .then(resp => {
        this.pressDataList = resp.payload
        this.pages = resp.totalPage
        this.loading = false
      })
  }
}
</script>

<style lang="scss" scoped>
  .section {
    padding: 0 20px 0 20px;
  }
</style>
